.theme-dark body {
  color: #ffffff;
  background-color: #000000;
}

.theme-dark a {
  color: #ffffff;
}

.theme-dark a:hover {
  color: #3aade1;
}

.theme-dark p {
  color: #e1e1e1;
}

.theme-dark .h1, .theme-dark .h2, .theme-dark .h3, .theme-dark .h4, .theme-dark .h5, .theme-dark .h6, .theme-dark h1, .theme-dark h2, .theme-dark h3, .theme-dark h4, .theme-dark h5, .theme-dark h6 {
  color: #ffffff;
}

.theme-dark .bg-f8f8f8 {
  background-color: #101010;
}

.theme-dark .form-control {
  background-color: #002e5b;
  color: #ffffff;
}

.theme-dark .form-control::-webkit-input-placeholder {
  color: #e1e1e1;
}

.theme-dark .form-control:-ms-input-placeholder {
  color: #e1e1e1;
}

.theme-dark .form-control::-ms-input-placeholder {
  color: #e1e1e1;
}

.theme-dark .form-control::placeholder {
  color: #e1e1e1;
}

.theme-dark .default-btn:hover, .theme-dark .default-btn:focus {
  color: #ffffff;
}

.theme-dark .bg-fafafa {
  background-color: #1d1c1c;
}

.theme-dark .navbar-area.is-sticky {
  background-color: #002e5b !important;
}

.theme-dark .navbar-area.is-sticky .pearo-nav .navbar {
  -webkit-box-shadow: unset !important;
          box-shadow: unset !important;
  background-color: #002e5b;
}

.theme-dark .pearo-nav .navbar {
  background-color: #000000;
  -webkit-box-shadow: -10px 10px rgba(0, 0, 0, 0.6);
          box-shadow: -10px 10px rgba(0, 0, 0, 0.6);
}

.theme-dark .pearo-nav .navbar .navbar-nav .nav-item a {
  color: #ffffff;
}

.theme-dark .pearo-nav .navbar .navbar-nav .nav-item a:hover, .theme-dark .pearo-nav .navbar .navbar-nav .nav-item a:focus, .theme-dark .pearo-nav .navbar .navbar-nav .nav-item a.active {
  color: #3aade1;
}

.theme-dark .pearo-nav .navbar .navbar-nav .nav-item:hover a, .theme-dark .pearo-nav .navbar .navbar-nav .nav-item.active a {
  color: #3aade1;
}

.theme-dark .pearo-nav .navbar .navbar-nav .nav-item .dropdown-menu {
  background: #002e5b;
}

.theme-dark .pearo-nav .navbar .navbar-nav .nav-item .dropdown-menu li a {
  color: #ffffff;
  border-bottom-color: #285581;
}

.theme-dark .pearo-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a {
  color: #ffffff;
}

.theme-dark .pearo-nav .navbar .others-option .option-item {
  color: #ffffff;
}

.theme-dark .pearo-nav .navbar .others-option .option-item .search-btn {
  color: #ffffff;
}

.theme-dark .pearo-nav .navbar .others-option .option-item .search-btn:hover {
  color: #3aade1;
}

.theme-dark .pearo-nav .navbar .others-option .option-item .close-btn {
  color: #ffffff;
}

.theme-dark .pearo-nav .navbar .others-option .option-item .close-btn:hover {
  color: #3aade1;
}

.theme-dark .pearo-nav .navbar .others-option .burger-menu {
  color: #ffffff;
}

.theme-dark .pearo-nav .navbar .others-option .burger-menu:hover {
  color: #3aade1;
}

.theme-dark .navbar-brand.white-logo {
  display: inline-block;
}

.theme-dark .navbar-brand.black-logo {
  display: none;
}

.theme-dark .pearo-responsive-menu .white-logo {
  display: inline-block;
}

.theme-dark .pearo-responsive-menu .black-logo {
  display: none;
}

.theme-dark .header-style-two::before {
  background-color: #002e5b;
}

.theme-dark .header-style-two .top-header .top-header-nav li {
  color: #ffffff;
}

.theme-dark .header-style-two .top-header .top-header-nav li a {
  color: #ffffff;
}

.theme-dark .header-style-two .top-header .top-header-nav li a:hover {
  color: #3aade1;
}

.theme-dark .header-style-two .top-header .top-header-right-side ul li {
  color: #e1e1e1;
}

.theme-dark .header-style-two .top-header .top-header-right-side ul li a {
  color: #ffffff;
}

.theme-dark .header-style-two .top-header .top-header-right-side ul li a:hover {
  color: #3aade1;
}

.theme-dark .header-style-two .top-header .top-header-right-side ul li a.default-btn {
  color: #ffffff;
}

.theme-dark .header-style-two .top-header .top-header-right-side ul li a.default-btn span {
  background: #ffffff;
}

.theme-dark .header-style-two .top-header .top-header-right-side ul li a.default-btn:hover {
  color: #002e5b;
}

.theme-dark .header-style-two .top-header .top-header-right-side ul li:hover .icon {
  background-color: #ffffff;
  color: #3aade1;
}

.theme-dark .header-style-two .pearo-nav .navbar {
  background-color: #000000;
  -webkit-box-shadow: -10px 10px rgba(0, 0, 0, 0.5);
          box-shadow: -10px 10px rgba(0, 0, 0, 0.5);
}

.theme-dark .header-style-two .pearo-nav .navbar .navbar-nav .nav-item a {
  color: #ffffff;
}

.theme-dark .header-style-two .pearo-nav .navbar .navbar-nav .nav-item a:hover, .theme-dark .header-style-two .pearo-nav .navbar .navbar-nav .nav-item a:focus, .theme-dark .header-style-two .pearo-nav .navbar .navbar-nav .nav-item a.active {
  color: #3aade1;
}

.theme-dark .header-style-two .pearo-nav .navbar .navbar-nav .nav-item:hover a, .theme-dark .header-style-two .pearo-nav .navbar .navbar-nav .nav-item.active a {
  color: #3aade1;
}

.theme-dark .header-style-two .pearo-nav .navbar .navbar-nav .nav-item .dropdown-menu li a {
  color: #ffffff;
}

.theme-dark .header-style-two .navbar-area.is-sticky {
  background-color: #002e5b !important;
}

.theme-dark .header-style-four::before {
  background-color: #002e5b;
}

.theme-dark .header-style-four .top-header .top-header-nav li {
  color: #ffffff;
}

.theme-dark .header-style-four .top-header .top-header-nav li a {
  color: #ffffff;
}

.theme-dark .header-style-four .top-header .top-header-nav li a:hover {
  color: #3aade1;
}

.theme-dark .header-style-four .top-header .top-header-right-side ul li {
  color: #e1e1e1;
}

.theme-dark .header-style-four .top-header .top-header-right-side ul li a {
  color: #ffffff;
}

.theme-dark .header-style-four .top-header .top-header-right-side ul li a:hover {
  color: #3aade1;
}

.theme-dark .header-style-four .top-header .top-header-right-side ul li a.default-btn {
  color: #ffffff;
}

.theme-dark .header-style-four .top-header .top-header-right-side ul li a.default-btn span {
  background: #ffffff;
}

.theme-dark .header-style-four .top-header .top-header-right-side ul li a.default-btn:hover {
  color: #3aade1;
}

.theme-dark .header-style-four .top-header .top-header-right-side ul li:hover .icon {
  background-color: #ffffff;
  color: #3aade1;
}

.theme-dark .header-style-four .pearo-nav .navbar {
  background-color: #000000;
  -webkit-box-shadow: -10px 10px rgba(0, 0, 0, 0.5);
          box-shadow: -10px 10px rgba(0, 0, 0, 0.5);
}

.theme-dark .header-style-four .pearo-nav .navbar .navbar-nav .nav-item .dropdown-menu li a {
  color: #ffffff;
}

.theme-dark .top-header-logo .white-logo {
  display: inline-block;
}

.theme-dark .top-header-logo .black-logo {
  display: none;
}

.theme-dark .main-banner::after {
  -webkit-filter: brightness(0);
          filter: brightness(0);
}

.theme-dark .single-box .content {
  background-color: #000000;
}

.theme-dark .about-title {
  border-color: #3f3f3f;
}

.theme-dark .single-about-box {
  background-color: #002e5b;
}

.theme-dark .single-about-box .icon {
  background-color: #285581;
}

.theme-dark .single-about-box:hover .icon {
  background-color: #3aade1;
}

.theme-dark .about-text-box {
  background-color: #002e5b;
}

.theme-dark .about-text-box h3 {
  border-color: #2e5377;
}

.theme-dark .about-text-box::before {
  background: #000000;
}

.theme-dark .single-services-box {
  background-color: #002e5b;
}

.theme-dark .single-services-box .read-more-btn {
  color: #ffffff;
}

.theme-dark .services-box {
  background-color: #002e5b;
}

.theme-dark .services-box .content .read-more-btn {
  color: #ffffff;
}

.theme-dark .services-slides.owl-theme .owl-dots .owl-dot:hover span {
  background-color: #3aade1;
}

.theme-dark .services-slides.owl-theme .owl-dots .owl-dot.active span {
  background-color: #3aade1;
}

.theme-dark .quote-area {
  background: #101010;
}

.theme-dark .quote-list-tab {
  background-color: #002e5b;
}

.theme-dark .quote-list-tab .tabs li a {
  color: #e1e1e1;
}

.theme-dark .quote-list-tab .tabs li a:hover {
  color: #ffffff;
  background-color: #101010;
}

.theme-dark .quote-list-tab .tabs li.current a {
  color: #ffffff;
  background-color: #101010;
}

.theme-dark .quote-list-tab .tabs_item {
  background-color: #101010;
}

.theme-dark .quote-list-tab .tabs_item form .form-control {
  background-color: #002e5b;
}

.theme-dark .quote-list-tab .tabs_item form .nice-select {
  color: #ffffff;
  background-color: #002e5b;
}

.theme-dark .quote-list-tab .tabs_item form .nice-select .list {
  background-color: #002e5b;
}

.theme-dark .quote-list-tab .tabs_item form .nice-select:after {
  border-color: #ffffff;
}

.theme-dark .quote-boxes-content {
  background-color: #002e5b;
}

.theme-dark .quote-boxes-content form .nice-select {
  background-color: #285581;
  color: #ffffff;
}

.theme-dark .quote-boxes-content form .nice-select .list {
  background-color: #000000;
}

.theme-dark .quote-boxes-content form .nice-select:after {
  border-color: #ffffff;
}

.theme-dark .quote-boxes-content form .form-control {
  background-color: #285581;
}

.theme-dark .quote-boxes-content form .default-btn {
  background-color: #000000;
}

.theme-dark .quote-boxes-content .image {
  position: absolute;
  left: -180px;
  bottom: -30px;
}

.theme-dark .single-team-box {
  background-color: #002e5b;
}

.theme-dark .single-blog-post {
  background-color: #002e5b;
}

.theme-dark .single-blog-post .post-image .date {
  background-color: #002e5b;
}

.theme-dark .single-blog-post .post-content .default-btn {
  background-color: #000000;
  color: #ffffff;
}

.theme-dark .single-blog-post:hover .post-image a img {
  -webkit-transform: scale(1.3) rotate(4deg);
          transform: scale(1.3) rotate(4deg);
}

.theme-dark .find-agent-area {
  background: #121212;
}

.theme-dark .find-agent-content .box {
  background-color: #002e5b;
}

.theme-dark .find-agent-content .box .icon {
  background-color: #285581;
  color: #ffffff;
}

.theme-dark .find-agent-contact-info ul li {
  color: #e1e1e1;
}

.theme-dark .find-agent-contact-info ul li span {
  color: #ffffff;
}

.theme-dark .find-agent-contact-info ul li::before {
  background-color: #414141;
}

.theme-dark .find-agent-contact-info ul li cite {
  background-color: #121212;
}

.theme-dark .join-area {
  background-color: #000000;
}

.theme-dark .join-content p {
  color: #ffffff;
}

.theme-dark .insurance-details-desc .features-list li {
  color: #e1e1e1;
}

.theme-dark blockquote, .theme-dark .blockquote {
  background-color: #002e5b;
}

.theme-dark blockquote p, .theme-dark .blockquote p {
  color: #ffffff;
}

.theme-dark blockquote::before, .theme-dark .blockquote::before {
  opacity: .10;
}

.theme-dark .pearo-post-navigation {
  border-color: #181818;
}

.theme-dark .prev-link-wrapper .prev-link-info-wrapper {
  color: #ffffff;
}

.theme-dark .prev-link-wrapper .meta-wrapper {
  color: #e1e1e1;
}

.theme-dark .next-link-wrapper .next-link-info-wrapper {
  color: #ffffff;
}

.theme-dark .next-link-wrapper .meta-wrapper {
  color: #e1e1e1;
}

.theme-dark .single-case-study-box {
  background-color: #002e5b;
}

.theme-dark .single-case-study-box .case-study-info {
  background-color: #002e5b;
}

.theme-dark .single-case-study-box .case-study-info .title a {
  color: #ffffff;
}

.theme-dark .single-case-study-box:hover .case-study-info {
  background-color: transparent;
}

.theme-dark .case-study-details-desc .case-study-details-info .single-info-box span {
  color: #e1e1e1;
}

.theme-dark .case-study-details-desc .case-study-details-info .single-info-box .social li a {
  color: #e1e1e1;
}

.theme-dark .case-study-details-desc .case-study-details-info .single-info-box .social li a:hover {
  color: #3aade1;
}

.theme-dark .single-events-box {
  background-color: #002e5b;
}

.theme-dark .single-events-box .events-box .events-content .content .location {
  color: #e1e1e1;
}

.theme-dark .events-details-header .back-all-events {
  color: #e1e1e1;
}

.theme-dark .events-details-header .back-all-events:hover {
  color: #3aade1;
}

.theme-dark .events-details-header .events-info-meta li {
  color: #e1e1e1;
}

.theme-dark .events-details-header .events-info-meta li i {
  color: #3aade1;
}

.theme-dark .events-details .events-info-links a {
  color: #ffffff;
}

.theme-dark .widget-area .widget .widget-title {
  border-color: #181818;
}

.theme-dark .widget-area .widget_search {
  background-color: #002e5b;
}

.theme-dark .widget-area .widget_search form .search-field {
  border-color: #14416d;
  color: #ffffff;
}

.theme-dark .widget-area .widget_search form .search-field::-webkit-input-placeholder {
  color: #e1e1e1;
}

.theme-dark .widget-area .widget_search form .search-field:-ms-input-placeholder {
  color: #e1e1e1;
}

.theme-dark .widget-area .widget_search form .search-field::-ms-input-placeholder {
  color: #e1e1e1;
}

.theme-dark .widget-area .widget_search form .search-field::placeholder {
  color: #e1e1e1;
}

.theme-dark .widget-area .widget_pearo_posts_thumb .item .info time {
  color: #e1e1e1;
}

.theme-dark .widget-area .widget_recent_entries ul li {
  color: #ffffff;
}

.theme-dark .widget-area .widget_recent_entries ul li a {
  color: #ffffff;
}

.theme-dark .widget-area .widget_recent_entries ul li a:hover {
  color: #3aade1;
}

.theme-dark .widget-area .widget_recent_entries ul li .post-date {
  color: #e1e1e1;
}

.theme-dark .widget-area .widget_recent_comments ul li {
  color: #e1e1e1;
}

.theme-dark .widget-area .widget_recent_comments ul li a {
  color: #e1e1e1;
}

.theme-dark .widget-area .widget_recent_comments ul li a:hover {
  color: #3aade1;
}

.theme-dark .widget-area .widget_archive ul li {
  color: #ffffff;
}

.theme-dark .widget-area .widget_archive ul li a {
  color: #ffffff;
}

.theme-dark .widget-area .widget_archive ul li a:hover {
  color: #3aade1;
}

.theme-dark .widget-area .widget_categories ul li {
  color: #e1e1e1;
}

.theme-dark .widget-area .widget_categories ul li a {
  color: #e1e1e1;
}

.theme-dark .widget-area .widget_categories ul li a:hover {
  color: #3aade1;
}

.theme-dark .widget-area .widget_meta ul li {
  color: #ffffff;
}

.theme-dark .widget-area .widget_meta ul li a {
  color: #ffffff;
}

.theme-dark .widget-area .widget_meta ul li a:hover {
  color: #3aade1;
}

.theme-dark .widget-area .tagcloud a {
  color: #ffffff;
  border-color: #181818;
}

.theme-dark .widget-area .tagcloud a:hover, .theme-dark .widget-area .tagcloud a:focus {
  border-color: #3aade1;
}

.theme-dark .widget-area .widget_events_details ul li {
  border-color: #181818;
}

.theme-dark .widget-area .widget_events_details ul li span {
  color: #ffffff;
}

.theme-dark .widget-area .widget_events_details ul li a {
  color: #e1e1e1;
}

.theme-dark .widget-area .widget_events_details ul li a:hover {
  color: #3aade1;
}

.theme-dark .single-pricing-box {
  background-color: #002e5b;
}

.theme-dark .single-pricing-box .pricing-header::after {
  -webkit-filter: brightness(0);
          filter: brightness(0);
}

.theme-dark .single-pricing-box .price {
  color: #ffffff;
}

.theme-dark .single-pricing-box .pricing-features-list li {
  color: #ffffff;
}

.theme-dark .single-pricing-box .pricing-features-list li.none-active i {
  color: #ffffff;
}

.theme-dark .faq-accordion .accordion .accordion-item {
  background: #002e5b;
}

.theme-dark .faq-accordion .accordion .accordion-title {
  color: #ffffff;
}

.theme-dark .faq-accordion .accordion .accordion-content {
  border-color: #181818;
}

.theme-dark .faq-contact-form form .form-control {
  background-color: #002e5b;
}

.theme-dark .bg-map {
  opacity: .15;
}

.theme-dark .pagination-area .page-numbers {
  background-color: #002e5b;
  color: #ffffff;
  -webkit-box-shadow: unset;
          box-shadow: unset;
}

.theme-dark .pagination-area .page-numbers.current, .theme-dark .pagination-area .page-numbers:hover, .theme-dark .pagination-area .page-numbers:focus {
  background: #3aade1;
}

.theme-dark .blog-details-desc .article-content .entry-meta ul li {
  color: #ffffff;
}

.theme-dark .blog-details-desc .article-content .entry-meta ul li a {
  color: #e1e1e1;
}

.theme-dark .blog-details-desc .article-content .entry-meta ul li a:hover {
  color: #3aade1;
}

.theme-dark .blog-details-desc .article-content .features-list li {
  color: #e1e1e1;
}

.theme-dark .blog-details-desc .article-footer .article-tags {
  color: #e1e1e1;
}

.theme-dark .blog-details-desc .article-footer .article-tags span {
  color: #ffffff;
}

.theme-dark .blog-details-desc .article-footer .article-tags a {
  color: #e1e1e1;
}

.theme-dark .blog-details-desc .article-footer .article-tags a:hover {
  color: #3aade1;
}

.theme-dark .blog-details-desc .article-footer .article-share .social li span {
  color: #e1e1e1;
}

.theme-dark .comments-area {
  background-color: #002e5b;
}

.theme-dark .comments-area .comment-body {
  border-color: #17426b;
  color: #ffffff;
}

.theme-dark .comments-area .comment-body .reply a {
  border-color: #17426b;
  color: #ffffff;
}

.theme-dark .comments-area .comment-body .reply a:hover {
  color: #ffffff;
  border-color: #3aade1;
}

.theme-dark .comments-area .comment-metadata {
  color: #e1e1e1;
}

.theme-dark .comments-area .comment-metadata a {
  color: #e1e1e1;
}

.theme-dark .comments-area .comment-metadata a:hover {
  color: #3aade1;
}

.theme-dark .comments-area .comment-respond label {
  color: #ffffff;
}

.theme-dark .comments-area .comment-respond input[type="date"], .theme-dark .comments-area .comment-respond input[type="time"], .theme-dark .comments-area .comment-respond input[type="datetime-local"], .theme-dark .comments-area .comment-respond input[type="week"], .theme-dark .comments-area .comment-respond input[type="month"], .theme-dark .comments-area .comment-respond input[type="text"], .theme-dark .comments-area .comment-respond input[type="email"], .theme-dark .comments-area .comment-respond input[type="url"], .theme-dark .comments-area .comment-respond input[type="password"], .theme-dark .comments-area .comment-respond input[type="search"], .theme-dark .comments-area .comment-respond input[type="tel"], .theme-dark .comments-area .comment-respond input[type="number"], .theme-dark .comments-area .comment-respond textarea {
  background-color: #002e5b;
  border-color: #17426b;
  color: #ffffff;
}

.theme-dark .comments-area .comment-respond input[type="date"]::-webkit-input-placeholder, .theme-dark .comments-area .comment-respond input[type="time"]::-webkit-input-placeholder, .theme-dark .comments-area .comment-respond input[type="datetime-local"]::-webkit-input-placeholder, .theme-dark .comments-area .comment-respond input[type="week"]::-webkit-input-placeholder, .theme-dark .comments-area .comment-respond input[type="month"]::-webkit-input-placeholder, .theme-dark .comments-area .comment-respond input[type="text"]::-webkit-input-placeholder, .theme-dark .comments-area .comment-respond input[type="email"]::-webkit-input-placeholder, .theme-dark .comments-area .comment-respond input[type="url"]::-webkit-input-placeholder, .theme-dark .comments-area .comment-respond input[type="password"]::-webkit-input-placeholder, .theme-dark .comments-area .comment-respond input[type="search"]::-webkit-input-placeholder, .theme-dark .comments-area .comment-respond input[type="tel"]::-webkit-input-placeholder, .theme-dark .comments-area .comment-respond input[type="number"]::-webkit-input-placeholder, .theme-dark .comments-area .comment-respond textarea::-webkit-input-placeholder {
  color: #ffffff;
}

.theme-dark .comments-area .comment-respond input[type="date"]:-ms-input-placeholder, .theme-dark .comments-area .comment-respond input[type="time"]:-ms-input-placeholder, .theme-dark .comments-area .comment-respond input[type="datetime-local"]:-ms-input-placeholder, .theme-dark .comments-area .comment-respond input[type="week"]:-ms-input-placeholder, .theme-dark .comments-area .comment-respond input[type="month"]:-ms-input-placeholder, .theme-dark .comments-area .comment-respond input[type="text"]:-ms-input-placeholder, .theme-dark .comments-area .comment-respond input[type="email"]:-ms-input-placeholder, .theme-dark .comments-area .comment-respond input[type="url"]:-ms-input-placeholder, .theme-dark .comments-area .comment-respond input[type="password"]:-ms-input-placeholder, .theme-dark .comments-area .comment-respond input[type="search"]:-ms-input-placeholder, .theme-dark .comments-area .comment-respond input[type="tel"]:-ms-input-placeholder, .theme-dark .comments-area .comment-respond input[type="number"]:-ms-input-placeholder, .theme-dark .comments-area .comment-respond textarea:-ms-input-placeholder {
  color: #ffffff;
}

.theme-dark .comments-area .comment-respond input[type="date"]::-ms-input-placeholder, .theme-dark .comments-area .comment-respond input[type="time"]::-ms-input-placeholder, .theme-dark .comments-area .comment-respond input[type="datetime-local"]::-ms-input-placeholder, .theme-dark .comments-area .comment-respond input[type="week"]::-ms-input-placeholder, .theme-dark .comments-area .comment-respond input[type="month"]::-ms-input-placeholder, .theme-dark .comments-area .comment-respond input[type="text"]::-ms-input-placeholder, .theme-dark .comments-area .comment-respond input[type="email"]::-ms-input-placeholder, .theme-dark .comments-area .comment-respond input[type="url"]::-ms-input-placeholder, .theme-dark .comments-area .comment-respond input[type="password"]::-ms-input-placeholder, .theme-dark .comments-area .comment-respond input[type="search"]::-ms-input-placeholder, .theme-dark .comments-area .comment-respond input[type="tel"]::-ms-input-placeholder, .theme-dark .comments-area .comment-respond input[type="number"]::-ms-input-placeholder, .theme-dark .comments-area .comment-respond textarea::-ms-input-placeholder {
  color: #ffffff;
}

.theme-dark .comments-area .comment-respond input[type="date"]::placeholder, .theme-dark .comments-area .comment-respond input[type="time"]::placeholder, .theme-dark .comments-area .comment-respond input[type="datetime-local"]::placeholder, .theme-dark .comments-area .comment-respond input[type="week"]::placeholder, .theme-dark .comments-area .comment-respond input[type="month"]::placeholder, .theme-dark .comments-area .comment-respond input[type="text"]::placeholder, .theme-dark .comments-area .comment-respond input[type="email"]::placeholder, .theme-dark .comments-area .comment-respond input[type="url"]::placeholder, .theme-dark .comments-area .comment-respond input[type="password"]::placeholder, .theme-dark .comments-area .comment-respond input[type="search"]::placeholder, .theme-dark .comments-area .comment-respond input[type="tel"]::placeholder, .theme-dark .comments-area .comment-respond input[type="number"]::placeholder, .theme-dark .comments-area .comment-respond textarea::placeholder {
  color: #ffffff;
}

.theme-dark .comments-area .comment-respond input[type="date"]:focus, .theme-dark .comments-area .comment-respond input[type="time"]:focus, .theme-dark .comments-area .comment-respond input[type="datetime-local"]:focus, .theme-dark .comments-area .comment-respond input[type="week"]:focus, .theme-dark .comments-area .comment-respond input[type="month"]:focus, .theme-dark .comments-area .comment-respond input[type="text"]:focus, .theme-dark .comments-area .comment-respond input[type="email"]:focus, .theme-dark .comments-area .comment-respond input[type="url"]:focus, .theme-dark .comments-area .comment-respond input[type="password"]:focus, .theme-dark .comments-area .comment-respond input[type="search"]:focus, .theme-dark .comments-area .comment-respond input[type="tel"]:focus, .theme-dark .comments-area .comment-respond input[type="number"]:focus, .theme-dark .comments-area .comment-respond textarea:focus {
  border-color: #3aade1;
}

.theme-dark .comments-area .comment-respond .comment-form-cookies-consent label {
  color: #e1e1e1;
}

.theme-dark .comments-area .comment-respond .form-submit input:hover, .theme-dark .comments-area .comment-respond .form-submit input:focus {
  background-color: #14416d;
}

.theme-dark .contact-form form .form-control {
  background-color: #002e5b;
}

.theme-dark .contact-info .contact-info-content h2 a:hover {
  color: #ffffff;
}

.theme-dark .contact-info .contact-info-content h2 a:not(:first-child) {
  color: #ffffff;
}

.theme-dark .contact-info .contact-info-content h2 a:not(:first-child):hover {
  color: #3aade1;
}

.theme-dark .contact-info .contact-info-content h2 span {
  color: #e1e1e1;
}

.theme-light .navbar-brand.white-logo {
  display: none;
}

.theme-light .pearo-responsive-menu .white-logo {
  display: none;
}

.theme-light .top-header-logo .white-logo {
  display: none;
}

@media only screen and (max-width: 991px) {
  .theme-dark .pearo-responsive-nav .pearo-responsive-menu.mean-container .mean-nav .others-option .option-item {
    color: #ffffff;
  }
  .theme-dark .pearo-responsive-nav .pearo-responsive-menu.mean-container .mean-nav .others-option .option-item .search-btn {
    color: #ffffff;
  }
  .theme-dark .pearo-responsive-nav .pearo-responsive-menu.mean-container .mean-nav .others-option .option-item .search-btn:hover {
    color: #3aade1;
  }
  .theme-dark .pearo-responsive-nav .pearo-responsive-menu.mean-container .mean-nav .others-option .option-item .close-btn {
    color: #ffffff;
  }
  .theme-dark .pearo-responsive-nav .pearo-responsive-menu.mean-container .mean-nav .others-option .option-item .close-btn:hover {
    color: #3aade1;
  }
  .theme-dark .pearo-responsive-nav .mean-container a.meanmenu-reveal {
    color: #ffffff;
  }
  .theme-dark .pearo-responsive-nav .mean-container a.meanmenu-reveal span {
    background: #ffffff;
  }
  .theme-dark .navbar-area {
    background-color: #000000;
  }
  .theme-dark .header-style-two .navbar-area {
    background-color: #000000;
  }
  .theme-dark .header-style-four .navbar-area {
    background-color: #000000;
  }
}
/*# sourceMappingURL=dark-style.css.map */