.papa {
     --bs-gutter-x: 0rem !important;
}

.contentPadding {
    padding: 0 !important;
}

.contentPadding .background {
    background-color: rgba(189, 54, 54);
}

.cardStyle h5 {
    padding: 20px;
    color: #ffffff;
}

.contact-form label {
    margin-bottom: 10px;
}
.cardStyle {
    padding: 0px 0px 0px 0px !important;  
}

.cardTitle {
    background-color: rgba(189, 54, 54);
}

.formTitle {
    color: #ffffff;
    font-size: 17px;
    font-weight: 600;
    line-height: 18px;
    padding: 30px;
    background-color: rgba(189, 54, 54);
    border: 1px dashed #ffffff;
    border: none;
    /* cursor: pointer; */
}

.formTitle:hover {
    background-color: rgba(176, 41, 41, 0.753);
}

.contentPadding .fa-trash {
    display: inline-block;
    border: 1px solid #ffffff;
    padding: 5px;
    border-radius: 50%;
    position: relative;
    left: 50%;
    cursor: pointer;
    /* background-color: #16dd16; */
}

.errorStyle {
    margin-top: 0.25rem;
    font-size: .875em;
    color: #dc3545;
}

.successStyle {
    margin-top: 0.25rem;
    font-size: .875em;
    color: #198754;
}

.checked {
    background-color: #ffffff !important;
    border-color: #ffffff !important;
}

/* .form-control.is-invalid, .was-validated .PhoneInputInput:invalid {

    border-color: #dc3545;
    padding-right: calc(1.5em + 0.75rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);

}

.form-control.is-valid, .was-validated .PhoneInputInput:valid {
    border-color: #198754;
    padding-right: calc(1.5em + 0.75rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
} */


.form-check-label {
    margin-top: 4px;
    margin-left: 8px
}

.form-check-dimensions {
    width: 25px;
    height: 25px
}

.form-check-input.is-valid~.form-check-label, .was-validated .form-check-input:valid~.form-check-label {
    border-color: #393735 !important;
    color: #393735 !important;
}

.form-check-input.is-valid, .was-validated .form-check-input:valid {
    border-color: #393735 !important;
}

.contact-form form .col-lg-6, .contact-form .col-sm-12{
    padding-left: 28px !important;
    padding-right: 28px !important;

  }

/* .react-tel-input {
    padding-left: 15px !important;
} */
  
  .form-check-input:checked {
    background-color: rgba(189, 54, 54) !important;
    border-color: rgba(189, 54, 54) !important;
  }

.form-footer-content-position {
    text-align: right;
    margin-top: 30px;
    margin-bottom: 30px;
    display: flex;
}
.form-footer-content-position button{
    
}

.vl {
    border-left: 8px solid #ffffff;
    height: auto;
}

.activee {
    background-color: rgba(176, 41, 41, 0.753)
}

.contact-form .row, .contact-form form {
    margin-top: 25px;
}

@media screen and (min-width: 767px) {

    .contact-form form .form-control {
        display: inline !important;
        height: 60px;
    }
}



.contact-form form select {
    /* text-transform: lowercase ! */
}

.form-footer-margin {
    margin-left: -3vw;
}

.not-display {
    display: none;
}

@media screen and (max-width:320){
    .form-footer-content-position {
      display:flex;
    }
  }